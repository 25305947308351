<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="d-flex align-items-center justify-content-between">
        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          {{ title }}
        </h3>
        <div></div>
      </div>
    </div>

    <div class="alert alert-danger" v-if="noData">
      {{ $t("tables.no data") }}
    </div>
    <div class="table-responsive" v-else>
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="customers"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.email") }}</th>
          <th>{{ $t("tables.phone") }}</th>
          <th>{{ $t("tables.last_login") }}</th>
          <th>{{ $t("tables.date") }}</th>
          <th>{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <th scope="row">
            <div class="media align-items-center">
              <span class="avatar rounded-circle mr-3">
                <img
                  :alt="row.item.name + 'Image'"
                  :src="'https://nqal.co/LiveNqal/public/' + row.item.photo"
                />
              </span>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.name }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{ row.item.email }}
          </td>
          <td class="budget ltr">
            {{ row.item.countryCode + "-" + row.item.phone }}
          </td>
          <td class="budget">
            {{
              moment(row.item.updated_at).format(
                "ddd [-] DD[-]MM[-]YYYY [/] HH:mm a"
              )
            }}
          </td>
          <td class="budget">
            {{
              moment(row.item.created_at).format(
                "ddd [-] DD[-]MM[-]YYYY [/] HH:mm a"
              )
            }}
          </td>
          <td class="budget text-right">
            <router-link
              :to="`/customers/${row.item.id}`"
              class="btn btn-primary btn-sm"
              ><i class="fas fa-eye"></i
            ></router-link>
            <button
              class="btn btn-success btn-sm"
              @click="editCustomer(row.item)"
            >
              <i class="fas fa-edit"></i>
            </button>
            <!-- <router-link
              :to="`/couriers/${row.item.id}`"
              class="btn btn-danger btn-sm"
              ><i class="fas fa-trash"></i
            ></router-link> -->
          </td>
        </template>
      </base-table>
    </div>

    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      />
    </div>
  </div>
  <!-- Edit Modal -->
  <div
    v-if="editModal"
    class="modal fade show d-block"
    id="editModal"
    tabindex="-1"
    aria-labelledby="editModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="editModalLabel">
            {{ $t("form.edit customer") }}
          </h5>
          <button
            type="button"
            class="btn-secondary"
            @click="editModal = false"
          >
            x
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-lg-6 mb-3">
                <label class="form-control-label">{{ $t("form.name") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updatedData.name"
                />
              </div>
              <div class="col-lg-6 mb-3">
                <label class="form-control-label">{{
                  $t("form.last_name")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updatedData.last_name"
                />
              </div>
              <div class="col-lg-6 mb-3">
                <label class="form-control-label">{{ $t("form.email") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updatedData.email"
                />
              </div>
              <div class="col-lg-6 mb-3">
                <label class="form-control-label">{{ $t("form.phone") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="updatedData.phone"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="editModal = false"
          >
            {{ $t("form.close") }}
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="updateCustomer(customerDetails)"
          >
            {{ $t("form.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
  name: "customers-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      loading: false,
      // Modals
      createModal: false,
      editModal: false,
      currentData: {},
      updatedData: {},
      noData: false,
      customers: "",
      page: 1,
      total_pages: "",
    };
  },
  created() {
    this.moment = moment;
    this.getCustomers("admin/administration/customers/index?page=1");
  },
  watch: {
    page: function () {
      this.getCustomers(
        "admin/administration/customers/index?page=" + this.page
      );
    },
  },
  methods: {
    getCustomers: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.data.length > 0) {
              this.noData = false;
              this.customers = response.data.data;
              this.page = response.data.meta.current_page;
              this.total_pages = response.data.meta.last_page;
            } else {
              this.noData = true;
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Update Customer
    editCustomer(customerData) {
      this.editModal = true;
      if (customerData) {
        this.currentData = customerData;
        this.updatedData.name = customerData.name;
        this.updatedData.last_name = customerData.last_name;
        this.updatedData.email = customerData.email;
        this.updatedData.phone = customerData.phone;
        this.updatedData.id = customerData.id;
      }
    },
    // Update Customer
    updateCustomer: function () {
      this.loading = true;
      let formData = this.toFormData(this.updatedData);
      var url;
      if (this.updatedData.id) {
        url = "admin/administration/customers/update";
      } else {
        url = "admin/administration/customers/store";
      }
      axios
        .post(url, formData)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.$swal({
                position: "center",
                icon: "error",
                title: response.data.message,
                showConfirmButton: true,
              });
            } else {
              this.customers.filter((item) => {
                if (this.updatedData.id === item.id) {
                  item.name = this.updatedData.name;
                  item.last_name = this.updatedData.last_name;
                  item.email = this.updatedData.email;
                  item.phone = this.updatedData.phone;
                }
              });
              this.updatedData = {};
              this.editModal = false;
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                text: response.data.message,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Form Fields
    toFormData: function () {
      var fd = new FormData();
      if (this.updatedData["id"]) {
        fd.append("_method", "put");
        fd.append("id", this.updatedData["id"]);
      }
      fd.append("name", this.updatedData["name"]);
      fd.append("email", this.updatedData["email"]);
      fd.append("phone", this.updatedData["phone"]);
      return fd;
    },
  },
};
</script>
<style scoped>
.ltr {
  direction: ltr;
}
</style>
